import React from 'react'
import { Link } from "gatsby"
import { Container, Row, Col, Button } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import Layout from '../../components/Layout'

const BlogTitle = styled.h1`
  padding-bottom: 25px;
`
const BlogContainer = styled.div`
  max-width: 740px;
  padding: 0 20px 75px 20px;
  margin: 0 auto 25px auto;
  border-bottom: 2px solid #7232E8;
  font-family: 'Georgia, Cambria, "Times New Roman", Times, serif';
  font-size: 21px;
  line-height: 1.58;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.003em;
  color: rgba(0,0,0,.84);
`
const BlogBottomContainer = styled.div`
  max-width: 740px;
  margin: 0 auto;
  padding: 0 20px;
  a {
    color: #7232E8;
  }
`
const BlogContent = styled.div`
  h1 {
    margin: 1.8em 0;
    line-height: 1.33;
    font-size: 2em;
    &:after {
      content: "";display: block;position: relative;top: .33em;border-bottom: 1px solid hsla(0,0%,50%,.2);
    }
  }
  h2 {
    margin: 1.8em 0; line-height: 1.33; font-size: 1.5em;
    &:after {
      content: "";display: block;position: relative;top: .33em;border-bottom: 1px solid hsla(0,0%,50%,.2);
    }
  }
  table {
    background-color: transparent; border-collapse: collapse; border-spacing: 0; display: table; border-color: grey;
  }
  th {
    border-right: 1px solid #dcdcdc; padding: 8px 12px; text-align: center;
    &:last-child {
      border-right: 0;
    }
  }
  td {
    border-top: 1px solid #dcdcdc; border-right: 1px solid #dcdcdc; padding: 8px 12px;
    &:last-child {
      border-right: 0;
    }
  }
  a {
    color: #7232E8;
  }
`
const BlogDate = styled.div`
  color: rgba(0,0,0,.54);
  font-size: 14px;
  text-align: right;
  margin-bottom: 15px;
`

export default ({data}) => {
  return (
    <Layout>
    <Helmet
      title="Privacy Policy | Bishop Software"
      meta={[
        { name: 'description', content: 'At Bishop Software, we value your privacy. Have a look at our guidelines for protecting your data.' },
        { name: 'keywords', content: 'software,privacy,policy' },
      ]}
    />

    <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #14134e 0, #512d5a 56%, #843b61 81%, #c16858 100%)', color: '#fafafa'}}>
      <Header dropdownGradient='linear-gradient(180deg, #d15e95 0, #c45d50 100%)' />
    </section>

    <section style={{padding: '70px 0'}}>
      <BlogContainer>
        <BlogDate>Effective Date: 04/16/2018</BlogDate>
        <BlogTitle>Privacy Policy</BlogTitle>
        <BlogContent>

<p>This privacy notice discloses the privacy practices for Bishop Software and our website; https://www.bishopsoft.com. This privacy notice applies solely to information collected by this website, except where stated otherwise. It will notify you of the following:</p>
<ul>
  <li>What information we collect;</li>
  <li>With whom it is shared;</li>
  <li>How it can be corrected;</li>
  <li>How it is secured;</li>
  <li>How policy changes will be communicated; and</li>
  <li>How to address concerns over misuse of personal data.</li>
</ul>
 

<h2>Information Collection, Use, and Sharing</h2>

<p>We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.</p>

<p>We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, e.g., to ship an order.</p>

<p>Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy. </p>

<h2>Your Access to and Control Over Information</h2>

<p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number provided on our website:</p>

<ul>
  <li>See what data we have about you, if any.</li>
  <li>Change/correct any data we have about you.</li>
  <li>Have us delete any data we have about you.</li>
  <li>Express any concern you have about our use of your data</li>
</ul> 

<h2>Sharing</h2>

<p>We share aggregated demographic information with our partners and advertisers. This is not linked to any personal information that can identify any individual person.</p>

<h2>Security</h2>

<p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>

<p>Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted to us in a secure way. You can verify this by looking for a closed lock icon at the bottom of your web browser, or looking for "https" at the beginning of the address of the web page.</p>

<p>While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (e.g. billing or customer service) are granted access to personally identifiable information. The computers/servers on which we store personally identifiable information are kept in a secure environment.</p>

<h2>Cookies</h2>

<p>We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance their experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.</p>

<p>Some of our business partners may use cookies on our site (e.g., advertisers). However, we have no access to or control over these cookies.</p>

<h2>Links</h2>

<p>This web site contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>

<h2>Surveys &amp; Contests</h2>

<p>From time-to-time our site requests information via surveys or contests. Participation in these surveys or contests is completely voluntary and you may choose whether or not to participate and therefore disclose this information. Information requested may include contact information (such as name and shipping address), and demographic information (such as zip code, age level). Contact information will be used to notify the winners and award prizes. Survey information will be used for purposes of monitoring or improving the use and satisfaction of this site.</p>

<h2>Notification of Changes</h2>

<p>Whenever material changes are made to the privacy notice, we will notify you by email.</p>

<p>If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at 1-833-424-7467 or via email at <a href="mailto:info@bishopsoft.com">info@bishopsoft.com</a>.</p>

        </BlogContent>
      </BlogContainer>
      <BlogBottomContainer>
        <Row>
          <Col>
            <Link to="/"><i className="fas fa-arrow-left" /> Home page</Link>
          </Col>
        </Row>
      </BlogBottomContainer>
    </section>

    <Footer />
  </Layout>
  )
}